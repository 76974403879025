<template>
  <div>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'settings-fulfillment-dispatch-create' }"
      back-to-list-path="settings-fulfillment-dispatch-list"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/trashList`,
      }"
    >
      <template
        #cell(actions)="{ data }"
      >
        <div
          class="d-flex justify-content-end"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover
            icon="LTrashIcon"
            class="lightIcon cursor-pointer border-dotted featherIcon"
            size="30"
            style="padding: 4px"
            :title="$t('Delete')"
            @click="remove(data)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="30"
            style="padding: 4px"
            class="featherIcon cursor-pointer border-dotted"
            @click="restoreDispatch(data.item.id)"
          />
        </div>
      </template>
      <template #cell(id)="{data}">
        <span style="display: flex; width: 20px; flex-direction: column; align-items: center">{{ data.item.id }}</span>
      </template>
      <template #cell(is_active)="{data}">
        {{ data.item['is_active'] ? 'Active' : 'Inactive' }}
      </template>
    </l-table>
  </div>
</template>
<script>

import {
  VBTooltip,
} from 'bootstrap-vue'
import LTable from '@/views/components/LTable/LTable.vue'
import config from '../config'

export default {
  name: 'DispatchTrashList',
  components: {
    LTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  created() {
    this.$emit('updateMenu', 'settings-fulfillment-dispatch-list')
  },
  methods: {
    restoreDispatch(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/restoreFromTrash`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
        .then(() => {
          this.refetchData()
        })
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      this.confirmNotification(this, { id: data.item.id, data }, `${this.MODULE_NAME}/del`)
        .then(() => {
          this.refetchData()
        })
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()

    return {
      MODULE_NAME,
      tableColumns,
    }
  },
}
</script>
