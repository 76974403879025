var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"create-page-url":{ name: 'settings-fulfillment-dispatch-create' },"back-to-list-path":"settings-fulfillment-dispatch-list","table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/trashList"),
    }},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"lightIcon cursor-pointer border-dotted featherIcon",staticStyle:{"padding":"4px"},attrs:{"icon":"LTrashIcon","size":"30","title":_vm.$t('Delete')},on:{"click":function($event){return _vm.remove(data)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"featherIcon cursor-pointer border-dotted",staticStyle:{"padding":"4px"},attrs:{"title":_vm.$t('Set to active'),"icon":"LLoadIcon","size":"30"},on:{"click":function($event){return _vm.restoreDispatch(data.item.id)}}})],1)]}},{key:"cell(id)",fn:function(ref){
    var data = ref.data;
return [_c('span',{staticStyle:{"display":"flex","width":"20px","flex-direction":"column","align-items":"center"}},[_vm._v(_vm._s(data.item.id))])]}},{key:"cell(is_active)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item['is_active'] ? 'Active' : 'Inactive')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }